import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Container from "../components/container";

const NotFoundPage = () => {
  return (
    <Layout>
      <Container>
        <h1 className="mt-16 mb-10">Strona nie znaleziona</h1>
        <p className="mb-10">
          Przepraszamy{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          strona której szukasz nie istnieje
          <br />
          <br />
          <Link to="/">Wróć na stronę główną</Link>.
        </p>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export function Head() {
  return (
    <>
      <title>Nie znaleziono</title>
      <meta
        name="description"
        content="Przepraszamy strona której szukasz nie istnieje"
      />
    </>
  );
}
